<template>
  <teleport to="body">
    <transition enter-active-class="transition ease-out duration-200 transform" enter-from-class="opacity-0" enter-to-class="opacity-100" leave-active-class="transition ease-in duration-200 transform" leave-from-class="opacity-100" leave-to-class="opacity-0">
      <div ref="modal-backdrop" class="fixed z-10 inset-0 overflow-y-auto bg-black bg-opacity-50" v-if="isCancel">
        <div class="flex items-start justify-center min-h-screen pt-24 text-center">
          <div class="bg-white rounded-lg text-left overflow-hidden shadow-xl w-9/12 md:w-1/2 lg:w-1/3" role="dialog" ref="modal" aria-modal="true" aria-labelledby="modal-headline" v-show="isCancel">
            <!-- step1 isCancel -->
            <div class="py-7 md:py-10 rounded-lg bg-white flex flex-col items-center justify-center shadow-md" v-if="iscancel">
              <div class="flex justify-center items-center mb-5">
                <img src="@/assets/image/svg_cancel_booking.png" class="pr-4 w-10 md:w-11" alt="" />
                <p class="md:text-2xl font-semibold text-gray-600">確定取消訂位嗎?</p>
              </div>
              <div class="w-full flex justify-center text-white">
                <button class="bg-grayColor-lightest rounded-md py-1 md:px-5 mr-3 w-18 h-6 text-xs md:text-xl md:h-11 md:w-32  " @click="closeModal">再想一下</button>
                <button class="bg-main rounded-md py-1 md:px-5 w-18 h-6 text-xs md:text-xl md:h-11 md:w-32 " @click="pushCancelling">確定取消</button>
              </div>
            </div>
            <!-- step2 cancelling -->
            <div class="mt-5 md:mt-10 py-7 md:py-10 rounded-lg bg-white flex flex-col items-center justify-center shadow-md" v-if="iscancelling">
              <img class="mb-5 w-14 md:w-32" src="@/assets/image/HandsBomb.png" alt="" />
              <p class="pb-5 md:text-2xl font-semibold text-gray-600">即將為您取消訂位...</p>

              <div class="w-full flex justify-center items-center text-white">
                <!-- <a class="rounded-md mr-3 text-grayColor-darkest text-xs font-medium md:text-lg underline cursor-pointer" @click="canceleOrder">重新預約</a> -->
                <button class="text-main border border-main rounded-md py-1 px-5 w-36 h-6 md:w-60 md:h-12 text-xs md:text-22" @click="accidentClick">不小心按到了({{countdown}})</button>
              </div>
            </div>
            <!-- step3 canceled -->
            <div class="py-7 md:py-10 rounded-lg bg-white flex flex-col items-center justify-center shadow-md" v-if="canceled">
              <div class="flex items-center">
                <img src="@/assets/image/svg_cancel_booking.png" class="pr-4 w-10 md:w-11" alt="" />
                <p class="md:text-2xl font-semibold text-gray-600">您的訂位已取消！</p>
              </div>
              <p class="pb-5 text-xs md:text-sm text-grayColor mt-3">期待能再次為您服務。</p>
              <div class="w-full flex justify-center items-center text-white">
                <a class="rounded-md mr-3 text-grayColor-darkest text-xs font-medium md:text-lg underline cursor-pointer" @click="backToRestaurant">重新預約</a>
                <button class="bg-main rounded-md py-1 md:px-5 w-18 h-6 text-xs md:text-xl md:h-11 md:w-32 " @click="closeModal">關閉視窗</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </teleport>
</template>

<script>
import { ref, watch } from "vue";

export default {
  name: "isCancel",
  data() {
    return {
      iscancel: true,
      iscancelling: false,
      canceled: false,
      countdown: 6,
      timer: null,
    };
  },
  emits: ["canceleOrder"],
  props: {
    orderStatus: {
      type: Object,
    },
    show: {
      type: Boolean,
      default: false,
    },
    storeId: {
     
    },
  },
  setup(props) {
    const isCancel = ref(false);
    // const modal = ref(null);
    // const { onClickOutside } = useClickOutside();
    function closeModal() {
      isCancel.value = false;
    }
    // onClickOutside(modal, () => {
    //   if (isCancel.value === true) {
    //     closeModal();
    //   }
    // });
    watch(
      () => props.show,
      (show) => {
        isCancel.value = show;
      }
    );
    return {
      closeModal,
      isCancel,
    };
  },
  methods: {
    pushCancelling() {
      this.iscancelling = true;
      this.iscancel = false;
      this.countDown();
      this.timer = setInterval(() => {
        if (this.countdown === 0 || this.isCancel === false) {
          this.clearTimer();
        } else {
          this.countDown();
        }
      }, 1000);
    },
    closeModal() {
      this.isCancel = false;
    },
    backToRestaurant() {
      this.$router.push(`/store/${this.storeId}`);
    },
    canceleOrder() {
      this.$emit("canceleOrder");
    },
    clearTimer() {
      clearInterval(this.timer);
      return (this.timer = null);
    },
    countDown() {
      this.countdown--;
      if (this.countdown === 0) {
        this.canceleOrder();
      }
    },
    accidentClick() {
      this.countdown = 6;
      this.clearTimer();
      this.iscancelling = false;
      this.isCancel = false;
      this.iscancel = true;
      // this.$emit("openModalValue", this.isCancel);
    },
  },
  watch: {
    countdown(newVal) {
      if (newVal === 0) {
        this.iscancelling = false;
        this.canceled = true;
        // this.confirmCancel = true;
      }
    },
    $route(to, from) {
      this.$router.go(0);
    },
  },
  created() {
    // this.orderStatus.phone = this.phone;
    // this.order.order_id = this.id;
  },
};
</script>