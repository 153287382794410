
<template>
  <div v-if="isGone">
    <div class="backgroundImg flex flex-col items-center ">
      <div class="gradient"></div>
      <div class="vld-parent">
        <!-- <loading :active="isLoading" :can-cancel="false" :is-full-page="true" :lock-scroll="true" :opacity="1" /> -->
      </div>
      <main class="mt-2 md:mx-3 flex flex-col bookingSuccessMain">
        <ModalDialog :show="orderSuccess" v-if="status === 1 || status === 2 " />
        <isCancel :show="openModal" :orderStatus="orderStatus" :storeId="id" @canceleOrder="canceleOrder" />
        <div class="statusShopInfo" v-if="!loading">
          <div class="storeLogo" v-if="store.store.images.logos.length > 0">
            <img :src="store.store.images.logos" class="w-full h-full object-cover" alt="Logo" v-if="store.store.images.logos.length > 0">
          </div>
          <div class="bg-grayColor-extralight md:p-5 flex justify-center items-center md:mr-10 noLogo" v-if="store.store.images.logos.length < 1">
            <p class="text-white text-xs md:text-lg text-center font-medium">店家尚未提供logo</p>
          </div>
          <div class="statusShopInfoName">
            <h1> {{ store.store.name }}</h1>
            <div class="flex items-center justify-center md:justify-start" v-if="!loading">
              <div class="flex items-center">
                <p class="font-bold mr-4 text-grayColor-darkest score-size"> {{ store.comments.avg_score }} </p>
                <div class="flex star mr-4 md:w-1/4">
                  <span v-for="(item, index) in average_star" class="star-item mr-2" :key="index" :class="item"></span>
                </div>
              </div>
              <p class="text-gray-500 font-semibold info-text"> ({{ store.comments.total }}則)</p>
            </div>
          </div>

        </div>

        <div class="flex flex-col md:flex-row text-sm text-gray-500 items-center store-detail" v-if="!loading">
          <div class="flex items-center md:mr-5 py-1">
            <span class="mr-2 ">
              <img src="@/assets/image/svg_phone.svg" class="info-icon" alt="phone" />
            </span>
            <p class="text-main info-text">{{ store.store.phone }}</p>
          </div>
          <div class="flex items-center md:mr-5 py-1">
            <span class="mr-2 ">
              <img src="@/assets/image/svg_address.svg" class="info-icon" alt="address" />
            </span>
            <!-- <p class="info-text">{{ store.store.address }}</p> -->
            <a class="info-text" :href="googleAddress" target="_blank"> {{ store.store.address }} </a>
          </div>
          <div class="flex items-center md:mr-5 py-1" v-if="store.store.website !== ''">
            <img src="@/assets/image/link.svg" class="info-icon mr-2" alt="website" />
            <a class="info-text" :href="store.store.website" target="_blank">{{ store.store.website }}</a>
          </div>
        </div>

        <div class="flex flex-col text-center text-xs md:text-22 font-medium md:pt-5 mt-3">
          <p class="text-grayColor user-name">{{order.name}}
            <span v-if="order.gender === 0 ">小姐</span>
            <span v-if="order.gender === 1 ">先生</span>
          </p>
          <p class="text-main text-xs md:text-22 user-status" v-if="(status === 0 ||status === 3) && !order.timeout ">您的訂位已取消！</p>
          <p class="text-main text-xs md:text-22 user-status" v-if="(status === 1 || status === 2) && !order.timeout">您已訂位成功！</p>
          <p class="text-main text-xs md:text-22 user-status" v-if="status === 4 && !order.timeout">您的預約已保留！</p>
          <p class="text-main text-xs md:text-22 user-status" v-if="status === 5 || status === 6 && !order.timeout">您已入座餐廳！</p>
          <p class="text-main text-xs md:text-22 user-status" v-if="status === 7 || order.timeout">您的訂位已逾時！</p>
        </div>

        <!-- box -->
        <div class="flex flex-col items-center justify-center  border border-main rounded-md w-full info-box" v-if="!loading">
          <div class="flex flex-col items-center justify-center info-box-middle">
            <p class="text-grayColo order-time">{{order.reservation_time}}</p>
            <div class="w-full border-t-2 border-b-2 border-gray-300 md:py-2 hidden md:block order-info-box">
              <div class="flex justify-between w-full ">
                <span class="md:p-2 flex justify-start">
                  <img class="pr-2" src="@/assets/image/svg_calendar2.svg" alt="" /></span>
                <span class="md:p-2 w-2/3 text-grayColor md:text-22 md:font-medium small-font text-right letter-spacing">
                  {{year}}年{{month}}月{{date}}日 (週{{chineseDay}})</span>
              </div>
              <div class="flex w-full text-center items-center">
                <span class="p-2 w-1/3 flex justify-start">
                  <img class="pr-2" src="@/assets/image/svg_group.svg" alt="" /></span>
                <div class="w-2/3 text-grayColor text-22 font-medium text-right">
                  <span class="font-semibold pr-2">{{order.adult_num}}</span>
                  <span class="font-normal pr-3">位成人</span>
                  <span class="font-semibold pr-2">{{order.child_num}}</span>
                  <span class="font-normal pr-2">位小孩</span>
                </div>
              </div>
              <div class="flex w-full text-center items-center">
                <span class="p-2 w-1/3 flex justify-start">
                  <img class="pr-2" src="@/assets/image/svg_childseats.svg" alt="" />
                </span>
                <div class="w-2/3 text-grayColor text-22 font-medium text-right">
                  <span class="pr-2">{{order.child_seat_num}} 張兒童椅</span>
                </div>
              </div>
            </div>

            <div class="w-full flex flex-col justify-center border-t border-b border-gray-300 md:hidden text-grayColor md:font-medium">
              <div class="flex w-full text-center items-center justify-between p-2">
                <span class="md:p-2 flex justify-center">
                  <img class="pr-2" src="@/assets/image/svg_calendar.svg" alt="" /></span>
                <span class="md:p-2 text-grayColor text-xs font-medium">
                  {{month}}月{{date}}日 ({{chineseDay}})</span>
              </div>
              <div class="flex w-full text-center items-center justify-between text-sm p-2">
                <div class="flex items-center pr-2">
                  <img class="pr-2" src="@/assets/image/svg_adult_sm.svg" alt="" />
                  <span>{{order.adult_num}}</span>
                </div>
                <div class="flex items-center pr-2">
                  <img class="pr-2" src="@/assets/image/svg_child_sm.svg" alt="" />
                  <span>{{order.child_num}}</span>
                </div>
                <div class="flex items-center">
                  <img class="pr-2" src="@/assets/image/svg_child_seat_sm.svg" alt="" />
                  <span>{{order.child_seat_num}}</span>
                </div>
                <!-- <div class="flex justify-center items-center text-grayColor text-22 font-medium">
            </div> -->
              </div>
            </div>
            <div class="text-grayColor text-center  w-full flex justify-center items-center end-status">
              <p class="" v-if="(status === 0 || status === 3) && !order.timeout">期待有機會再為您服務！</p>
              <p v-if="(status === 1 || status === 2) && !order.timeout && store.store.limit_minute > 0">用餐時間為 {{store.store.limit_minute}} 分鐘，<br> 人數到齊後方可為您帶位入座。</p>
              <p v-if="(status === 1 || status === 2) && !order.timeout && store.store.limit_minute === 0">人數到齊後方可為您帶位入座。</p>
              <p v-if="status === 4 && !order.timeout">座位將為您保留{{store.store.hold_order_minute}}分鐘，逾時取消不另行通知。<br> 如仍需取消座位，請按取消座位。</p>
              <p v-if="status === 5 || status === 6 && !order.timeout">感謝您的光臨，<br> 期待下次還有機會再為您服務</p>
              <p v-if="status === 7 || order.timeout">很抱歉已超過座位保留時間。<br> 若您有任何問題，請與餐廳聯絡或重新預約。</p>
            </div>

            <div class="text-white text-lg flex md:flex-row flex-col items-center justify-center mt-2" v-if="(status === 1 ||status === 2 || status === 4) && !order.timeout">
              <a class="md:flex items-center underline text-main font-semibold md:mr-5 text-xs md:text-base hidden" :href="calendarUrl" target="_blank">
                <img class="mr-2 hidden md:block" src="@/assets/image/svg_calendar.svg" alt="" />
                加入行事曆
              </a>
              <div class="relative">
                <button class="status-btn bg-main text-xs md:text-22 focus:outline-none w-105 h-6 md:w-40 md:h-12 keepMdHide " v-if="(status === 1 || status === 2) && !inTwentyFour" @click="toggleShareLink = !toggleShareLink">分享給朋友</button>

                <transition enter-active-class="transition ease-out duration-300 transform" enter-from-class="opacity-0" enter-to-class="opacity-100" leave-active-class="transition ease-in duration-200 transform" leave-from-class="opacity-100" leave-to-class="opacity-0" v-show="toggleShareLink" class="shareToFriendBox origin-top-right rounded-lg keepMdHide">
                  <div class="rounded-md bg-gray-lightest shadow-xs text-gray-second ">
                    <div class="flex justify-between w-full rounded-lg">
                      <a :href="line" target="_blank" class="line-box">
                        <i class="fab fa-line"></i>分享至LINE
                      </a>
                      <div class="relative status-copy-input">
                        <!-- <input disabled class="inputStyle w-full" type="text" :placeholder="copyContent" /> -->
                        <button class="copyIcon" v-clipboard:copy="copyContent" @click="handleSuccess" title="Copy Link" name="copy">
                          <i class="far fa-clone text-main"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </transition>
              </div>

              <button target="_blank" class="status-btn bg-main text-xs md:text-22 focus:outline-none w-105 h-6 md:w-40 md:h-12 keepMdHide" :class="{'hidden':hideBtn }" v-if="inTwentyFour &&status !== 4" @click="keepOrer">保留訂位</button>

              <button class="status-btn bg-grayColor-lightest md:py-2 focus:outline-none text-xs md:text-22 w-105 h-6 md:w-40 md:h-12" v-if="isCanCancel" @click="openModalway">取消訂位</button>

              <button target="_blank" class="status-btn bg-main text-xs md:text-22 focus:outline-none w-105 h-6 md:w-40 md:h-12 keepSmHide" :class="{'hidden':hideBtn }" v-if="inTwentyFour && status !== 4" @click="keepOrer">保留訂位</button>

              <div class="relative">
                <button class="status-btn bg-main text-xs md:text-22 focus:outline-none w-105 h-6 md:w-40 md:h-12 keepSmHide" v-if="(status === 1 || status === 2) && !inTwentyFour" @click="toggleShareLink = !toggleShareLink">分享給朋友</button>

                <transition enter-active-class="transition ease-out duration-300 transform" enter-from-class="opacity-0" enter-to-class="opacity-100" leave-active-class="transition ease-in duration-200 transform" leave-from-class="opacity-100" leave-to-class="opacity-0" v-show="toggleShareLink" class="shareToFriendBox origin-top-right rounded-lg keepSmHide">
                  <div class="rounded-md bg-gray-lightest shadow-xs text-gray-second ">
                    <div class="flex justify-between w-full rounded-lg">
                      <a :href="line" target="_blank" class="line-box">
                        <i class="fab fa-line"></i>分享至LINE
                      </a>
                      <div class="relative status-copy-input">
                        <!-- <input disabled class="inputStyle w-full" type="text" :placeholder="copyContent" /> -->
                        <button class="copyIcon" v-clipboard:copy="copyContent" @click="handleSuccess" title="Copy Link" name="copy">
                          <i class="far fa-clone text-main"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </transition>
              </div>

              <a class="flex items-center justify-between underline text-main font-semibold md:mr-5 text-xs md:text-base md:hidden mt-2" :href="calendarUrl" target="_blank">
                <img class="mr-1 md:hidden" src="@/assets/image/svg_calendar.svg" alt="" />
                加入行事曆
              </a>
            </div>

            <div class="flex flex-col items-center justify-center mt-1 py-3 md:py-7" v-if="status !== 0 && status !== 3" :class="{leaveComment : status === 1 || status === 2 || status ===  7 || status=== 4}">
              <p class=" text-grayColor-darkest mb-4 text-8 md:text-lg " v-if="status === 1 || status === 2 || status === 4 || status === 5 || status === 7">入座後即可寫下評論</p>
              <div class="flex flex-col items-center md:flex-row justify-center mb-5 ">
                <div class="tar-item flex items-center justify-center md:mr-4">
                  <span class="star-item off star-item mr-2"></span>
                  <span class="star-item off star-item mr-2"></span>
                  <span class="star-item off star-item mr-2"></span>
                  <span class="star-item off star-item mr-2"></span>
                  <span class="star-item off star-item mr-2"></span>
                </div>
                <button class="border border-main rounded-md py-1 px-3 md:py-2 md:px-5 mt-3 md:mt-0 text-main md:text-22 text-xs font-hideEdit w-20 h-7 md:w-40 md:h-12  " @click="leaveComment">寫下評論</button>
              </div>
            </div>

            <div class="flex justify-around items-center" v-if="!loading">
              <button class="mr-2 w-18 h-7 md:w-36 md:h-12 py-1 px-1 md:px-5 md:text-base text-xs rounded-md border border-grayColor text-grayColor hover:bg-gray-200 focus:outline-none" @click="clickMenu('food')" :class="{ 'activeMenu': this.activeMenuTab === 'food'}" v-if="store.store.images.menus.length > 0 ">
                <span class="flex items-center justify-center">看菜單
                  <img class="pl-1 hidden md:block" src="@/assets/image/svg_menu15.svg" alt="food-menu" />
                  <img class="w-4 pl-1 md:hidden block" src="@/assets/image/svg_menu.svg" alt="food-menu" />
                </span>
              </button>
              <button class="mr-2 w-18 h-7 md:w-36 md:h-12 py-1 px-1 md:px-5 md:text-base text-xs rounded-md border border-grayColor text-grayColor hover:bg-gray-200 focus:outline-none" :class="{ 'activeMenu': this.activeMenuTab === 'wine'}" v-if=" store.store.images.wines.length > 0" @click="clickMenu('wine')">
                <span class="flex items-center justify-center">看酒單
                  <img class="pl-1 hidden md:block" src="@/assets/image/svg_wine_15.svg" alt="wine-menu" />
                  <img class="w-4 pl-1 md:hidden block" src="@/assets/image/svg_wine.svg" alt="wine-menu" />
                </span>
              </button>
            </div>
          </div>

          <!-- START -->
          <div class="mt-5 w-full " v-if="!loading">
            <div v-if="store.store.images.menus.length > 0 || store.store.images.wines.length > 0">
              <div class="food-menu" v-if="activeMenuTab === 'food'">

                <flickity class="flickity md:block hidden" ref="flickity" :class="{hidebtn : store.store.images.menus.length < 4 }" :options="flickityOptions" v-viewer>
                  <img v-for="src in store.store.images.menus" :key="src" :src="src" alt="" class=" cursor-pointer carousel-cell" />
                </flickity>

                <flickity class="flickity block md:hidden" ref="flickity" :class="{hidebtnSm : store.store.images.menus.length < 3 }" :options="flickityOptions" v-viewer>
                  <img v-for="src in store.store.images.menus" :key="src" :src="src" alt="" class=" cursor-pointer carousel-cell" />
                </flickity>

                <!-- <Carousel :settings="settings" :transition="1000" class="menuSwiperSmStatus" :breakpoints="breakpoints" :wrap-around="false">
                  <Slide v-for="src in store.store.images.menus" :key="src">
                    <img :src="src" alt="" class="h-full w-full cursor-pointer slide-img" v-viewer="{navbar: false}" />
                  </Slide>
                  <template #addons>
                    <div>
                      <Navigation class="focus:outline-none smHidden " v-if="store.store.images.menus.length > 3" />
                      <Navigation class="focus:outline-none webHidden " v-if="store.store.images.menus.length > 2" />
                    </div>
                  </template>
                </Carousel> -->
              </div>

              <div class="wine-menu" v-if="activeMenuTab === 'wine'">
                <flickity class="flickity md:block hidden" ref="flickity" :class="{hidebtn : store.store.images.wines.length < 4}" :options="flickityOptions" v-viewer>
                  <img v-for="src in store.store.images.wines" :key="src" :src="src" alt="" class=" cursor-pointer carousel-cell" />
                </flickity>

                <flickity class="flickity block md:hidden" ref="flickity" :class="{hidebtnSm : store.store.images.wines.length < 3 }" :options="flickityOptions" v-viewer>
                  <img v-for="src in store.store.images.wines" :key="src" :src="src" alt="" class=" cursor-pointer carousel-cell" />
                </flickity>
                <!-- <Carousel :settings="settings" :transition="1000" class="menuSwiperSmStatus" :breakpoints="breakpoints" :wrap-around="false">
                  <Slide v-for="src in store.store.images.wines" :key="src">
                    <img :src="src" alt="" class="h-full w-full cursor-pointer slide-img" v-viewer="{navbar: false}" />
                  </Slide>
                  <template #addons>
                    <Navigation class="focus:outline-none " v-if="store.store.images.wines.length > 3" />
                  </template>
                </Carousel> -->
              </div>

              <div class="flex justify-center items-center tip-enlarge">
                <img class="pr-2" src="@/assets/image/svg_magnifier.svg" alt="wine-menu" />
                <p class="text-grayColor cursor-pointer">點擊圖片可放大</p>
              </div>
            </div>
          </div>
          <!--END  -->
        </div>
      </main>

      <div v-if="!loading">
        <div class="callSection" v-if="(status === 1  || status === 2  || status === 4 || status === 5 || status === 6) && !inTwentyFour && !order.timeout">
          <img class=" my-10 md:my-16" src="@/assets/image/twdd_marketing_img.png" alt="call twdd image">
          <button class="text-main border border-main rounded-md font-bold focus:outline-none" @click="handleCall">呼叫代駕</button>
        </div>

        <div class="bookingSection" v-if="status === 0 || status === 3 ||  status === 7 || inTwentyFour || order.timeout">
          <img class=" my-10 md:my-16" src="@/assets/image/joymap-banner-booking.png" alt="order image">
          <button class="text-main border border-main rounded-md font-bold focus:outline-none" @click="handleOrder">前往訂位</button>
        </div>
      </div>

    </div>
    <Footer />
  </div>
  <div v-else>
    <loading :active="true" :can-cancel="false" :is-full-page="true" :lock-scroll="true" :opacity="1" />
  </div>
</template>

<script>
import ModalDialog from "@/components/ModalDialog.vue";
import isCancel from "@/components/isCancel.vue";
import Footer from "@/components/Footer.vue";

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { Carousel, Navigation, Slide } from "vue3-carousel";
import "vue3-carousel/dist/carousel.css";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/dist/sweetalert2.min.css";
import moment from "moment";
import Flickity from "vue-flickity";
import { ref } from "vue";
const lengths = 5;
const starOn = "on";
const starHalf = "half";
const starOff = "off";

export default {
  name: "BookingSuccess",
  components: {
    ModalDialog,
    isCancel,
    Footer,
    Carousel,
    Slide,
    Navigation,
    Flickity,
    Loading,
  },
  setup() {
    const orderSuccess = ref(false);
    const isCancel = ref(false);
    const overtime = ref(false);
    const seated = ref(false);
    return {
      orderSuccess,
      isCancel,
      overtime,
      seated,
    };
  },
  data() {
    return {
      flickityOptions: {
        initialIndex: 1,
        prevNextButtons: true,
        pageDots: false,
        wrapAround: false,
        freeScroll: false,
        contain: true,
      },

      isLoading: true,
      copyContent: "",
      googleAddress: "",
      website: "",
      storeLink: "",
      toggleShareLink: false,
      loading: false,
      store: {},
      newData: [],
      tabshow: "true",
      openModal: false,
      timer: null,
      month: "",
      day: "",
      year: "",
      date: "",
      clickWeek: "",
      order: {},
      orderStatus: {
        order_id: "",
        phone: "",
        status: "",
      },
      id: "", //storeId
      storeCode: "",
      status: "",
      hideBtn: false,
      line: "",
      quoto: "",
      calendarUrl: "",
      calendarTime: "",
      // settings: {
      //   itemsToShow: 2,
      // },
      // breakpoints: {
      //   700: {
      //     itemsToShow: 3,
      //   },
      // },
      isGone: false,
    };
  },
  methods: {
    openModalway() {
      this.openModal = !this.openModal;
    },
    async getReservation() {
      let response = await this.getResData();
      return new Promise((resolve) => {
        this.store = response.data.return;
        this.id = this.store.store.id;
        // this.$store.commit("STOREID", this.store.store.id);
        this.order = response.data.return.order;
        this.status = this.order.status;
        this.orderStatus.order_id = this.$route.params.id;
        this.orderStatus.phone = this.order.phone;
        this.storeLink = `${process.env.VUE_APP_JOYMAP_URL}/store/${this.store.store.slug}`;
        this.quoto = `我發現一間超棒的店家「${this.store.store.name}」！！！分享給你～～～`;
        this.googleAddress = `https://www.google.com.tw/maps/place/${this.store.store.address}`;
        this.copyContent = `我預訂了「${this.store.store.name}」在 ${
          this.order.reservation_date
        }，${this.order.reservation_time}，共${
          this.order.adult_num + this.order.child_num
        }位。餐廳資訊:${this.storeLink} `;

        const hour = this.order.reservation_time.replace(
          /[.,\/#!$%\^&\*;:{}=\-_`~()]/g,
          ""
        );
        this.loading = false;
        this.calendarTime = hour;
        this.getDate();
        this.lineUrl();

        resolve();
      });
    },
    async getResData() {
      const api = `${process.env.VUE_APP_API}/order/${this.$route.params.id}`;
      let res = await this.$http.get(api);

      return res;
    },
    getDate() {
      let date = new Date(this.order.reservation_date);
      this.month = date.getMonth() + 1;
      let thisDate = date.getDate();
      this.year = date.getFullYear();
      this.day = date.getDay();

      if (thisDate < 10) {
        this.date = "0" + thisDate;
      } else {
        this.date = thisDate;
      }

      this.calendarUrl = `http://www.google.com/calendar/event?action=TEMPLATE&text=${this.store.store.name}&dates=${this.year}${this.month}${this.date}T${this.calendarTime}/${this.year}${this.month}${this.date}T${this.calendarTime}&location=${this.store.store.address}&trp=false`;
    },
    canceleOrder() {
      this.status = 0;
      this.orderStatus.status = 0;
      const api = `${process.env.VUE_APP_API}/order`;
      this.$http
        .put(api, this.orderStatus)
        .then((response) => {
          // Swal.fire({
          //   icon: "info",
          //   text: "訂位已取消",
          //   showConfirmButton: false,
          //   timer: 3000,
          //   allowOutsideClick: false,
          // });
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            text: "取消失敗",
            showConfirmButton: false,
            timer: 3000,
            allowOutsideClick: false,
          });
        });
    },
    keepOrer() {
      this.orderStatus.status = 4;
      this.hideBtn = true;
      const api = `${process.env.VUE_APP_API}/order`;
      const order = this.orderStatus;
      this.$http
        .put(api, order)
        .then((response) => {
          this.status = 4;
          Swal.fire({
            icon: "success",
            text: "座位已保留",
            showConfirmButton: false,
            timer: 3000,
            allowOutsideClick: false,
          });
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            text: "保留失敗",
            showConfirmButton: false,
            timer: 3000,
            allowOutsideClick: false,
          });
        });
    },
    showTab() {
      if (this.store.store.images.menus.length < 1) {
        this.$store.commit("ACTIVEMENU", "wine");
      } else {
        this.$store.commit("ACTIVEMENU", "food");
      }
    },
    handleCall() {
      const url = `${process.env.VUE_APP_CALL_URL}/call?phone=${this.order.phone}`;
      window.open(url, "_blank");
    },
    handleOrder() {
      const url = `${process.env.VUE_APP_JOYMAP_URL}/store/${this.store.store.slug}`;

      window.open(url, "_blank");
    },
    leaveComment() {
      this.$router.push(`/order/comment/${this.orderStatus.order_id}`);
    },
    lineUrl() {
      const lineUrl = `http://line.naver.jp/R/msg/text/?我預訂了${
        this.store.store.name
      }於${this.order.reservation_date}，${this.order.reservation_time}，${
        this.order.adult_num
      }位大人 ${this.order.child_num}位小孩 共${
        this.order.adult_num + this.order.child_num
      }位!%0D%0A${this.storeLink}`;
      this.line = lineUrl;
    },
    handleSuccess() {
      this.toggleShareLink = false;
      Swal.fire({
        html: '<i class="fas fa-check mr-2"></i>已複製',
        showConfirmButton: false,
        timer: 2000,
        width: 150,
        padding: "0",
      });
    },
    clickMenu(menu) {
      this.$store.dispatch("clickMenu", menu);
    },
  },
  computed: {
    activeMenuTab() {
      return this.$store.state.activeMenuTab;
    },
    average_star() {
      let result = [];
      let score = Math.floor(this.store.comments.avg_score * 2) / 2;
      let starhalf = score % 1 != 0 ? true : false;
      let fullstar = Math.floor(score);

      for (let i = 0; i < fullstar; i++) {
        result.push(starOn);
      }
      if (starhalf) {
        result.push(starHalf);
      }
      if (result.length < lengths) {
        let offstar = lengths - result.length;
        for (let i = 0; i < offstar; i++) {
          result.push(starOff);
        }
      }
      return result;
    },
    chineseDay() {
      if (this.day === 1) {
        return "一";
      } else if (this.day === 2) {
        return "二";
      } else if (this.day === 3) {
        return "三";
      } else if (this.day === 4) {
        return "四";
      } else if (this.day === 5) {
        return "五";
      } else if (this.day === 6) {
        return "六";
      } else if (this.day === 0) {
        return "日";
      }
    },
    inTwentyFour() {
      const nowDate = moment().format("YYYY-MM-DD HH:mm"); //線在時間 10/27
      const year = moment(this.order.reservation_date).format("YYYY");
      const month = moment(this.order.reservation_date).format("MM");
      const day = moment(this.order.reservation_date).format("DD");
      const hour = this.order.reservation_time.slice(0, 2);
      const mins = this.order.reservation_time.slice(3, 5);
      const orderDate = moment([year, month - 1, day, hour, mins]).format(
        "YYYY-MM-DD HH:mm" //預定時間 10/28
      );
      const oneDayBefore = moment(new Date(year, month - 1, day, hour, mins))
        .subtract(24, "hours")
        .format("YYYY-MM-DD HH:mm");

      if (moment(nowDate).isBetween(oneDayBefore, orderDate)) {
        return true;
      } else {
        return false;
      }
    },
    remindInfo() {
      const orderDate = Date.parse(
        new Date(this.order.reservation_date).toDateString()
      );
      const beforeOrder = orderDate - 1000 * 60 * 60 * 24;
      const nowDate = new Date().getTime();

      if (this.status === 4) {
        return false;
      } else {
        if (nowDate < orderDate && nowDate >= beforeOrder) {
          return true;
        } else {
          return false;
        }
      }
    },
    isCanCancel() {
      const nowTime = moment()._d; //19:00
      // const orderTime = moment().format("2021-11-30 20:00");
      const orderTime = moment().format(
        `${this.order.reservation_date} ${this.order.reservation_time}`
      );
      const final_cancel = this.store.store.final_cancel_minute;
      const limitTime = moment(orderTime).subtract(final_cancel, "minutes")._d; 
      //如果現在時間 大於限制時間 就true
      if (moment(nowTime).isBefore(limitTime)) {
        return true;
      } else {
        return false;
      }
    },

    calendarMonth() {
      if (this.month < 10) {
        const month = "0" + this.month;
        return month;
      }
    },
    calendarDay() {
      if (this.date < 10) {
        const date = "0" + this.date;
        return date;
      } else {
        return this.date;
      }
    },
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  async created() {
    // this.orderStatus.order_id = parseInt(this.$route.params.id, 10);
    // this.id = this.$route.query.id;
    this.website = location.href;
    this.getReservation().then(() => {
      if (this.order.is_comment === 1 && this.isGone == false) {
        this.$router.push(`/commentsuccess`);
        // return;
      } else {
        this.isGone = true;
      }
    });
    this.isLoading = false;
  },
};
</script>

<style lang="scss">
.bookingSection {
  position: relative;
}

.bookingSection button {
  position: absolute;
  top: 26px;
  left: 28px;
  width: 156px;
  height: 52px;
  font-size: 1.375rem;
}

.bookingSection button:hover {
  color: white;
  transition: all 0.2s ease-in-out;
  background: #fa6c3e;
}

@media (max-width: 767px) {
  .bookingSection button {
    width: 78px;
    height: 32px;
    font-size: 0.6875rem;
  }
}

.hideBtnWeb {
  display: none;
}

@media (min-width: 768px) {
  .hideBtnWeb {
    display: block;
  }
}

.slide-img {
  padding: 0 5px 0 0;
}
.carousel__icon {
  margin: 0 auto;
  width: 10px;
  height: 10px;
}

@media (min-width: 768px) {
  .carousel__icon {
    margin: 0 auto;
    width: 16px;
    height: 16px;
  }
}
.tip-enlarge {
  padding: 8px 0;
  font-size: 12px;
  font-weight: 400;
  img {
    width: 20px;
  }
}
@media (min-width: 768px) {
  .tip-enlarge {
    padding: 24px 0;
    font-size: 16px;
    img {
      width: 28px;
    }
  }
}

.menuSwiperSmStatus {
  padding: 0 30px 0px 30px;
  // transform: translateX(-251.333px);
}

@media (min-width: 768px) {
  .menuSwiperSmStatus {
    padding: 0 42px 10px 40px;
  }
}
.status-copy-input .inputStyle {
  font-size: 12px;
}

@media (min-width: 768px) {
  .status-copy-input .inputStyle {
    font-size: 16px;
  }
}

.line-box {
  display: flex;
  align-items: center;
  color: #00c300;
  font-size: 14px;
  i {
    background: white;
    font-size: 20px;
    color: #00c300;
    margin-right: 5px;
  }
}
@media (min-width: 768px) {
  .line-box {
    font-size: 16px;
    i {
      font-size: 30px;
    }
  }
}

.line-icon {
  width: 80px;
  margin-bottom: 5px;
}

.swal2-html-container {
  margin: 18px;
}
.shareToFriendBox {
  box-shadow: 0 0 15px -3px rgba(0, 0, 0, 0.2);
  background-color: white;
  position: absolute;
  bottom: -45px;
  left: -20px;
  z-index: 9999;
  width: 150px;
  padding: 8px;
}
@media (min-width: 768px) {
  .shareToFriendBox {
    bottom: -60px;
    left: 0;
    z-index: 9999;
    width: 200px;
    padding: 12px;
  }
}
.status-btn {
  margin: 0 0 6px 0;
  border-radius: 3px;
}
@media (min-width: 768px) {
  .status-btn {
    margin: 0 12px 0 0;
  }
}
.end-status {
  font-weight: 400;
  margin: 12px 0;
  font-size: 12px;
}
@media (min-width: 768px) {
  .end-status {
    margin: 24px 0;
    font-size: 22px;
    // font-weight: 600;
  }
}
.letter-spacing {
  letter-spacing: 1.2px;
}
.order-info-box {
  padding: 8px 40px;
}
.info-box {
  margin-top: 24px;
}
.noLogo {
  width: 60px;
  height: 60px;
  padding: 1px 1px;
  margin-bottom: 18px;
}
@media (min-width: 768px) {
  .noLogo {
    width: 120px;
    height: 120px;
    padding: 37px 17px;
    margin-bottom: 0px;
  }
}
.info-text {
  font-size: 12px;
  font-weight: 400;
}

@media (min-width: 1024px) {
  .info-text {
    font-size: 16px;
  }
}

.info-icon {
  width: 16px;
}
@media (min-width: 768px) {
  .info-icon {
    width: 100%;
  }
}
.order-time {
  font-size: 16px;
  font-weight: 700;
  margin: 12px 0 12px 0;
  color: #737992;
}
@media (min-width: 768px) {
  .order-time {
    font-size: 46px;
    margin: 12px 0 12px 0;
  }
}

.user-status {
  padding: 2px 0 4px 0;
  font-size: 15px;
  font-weight: bold;
}
@media (min-width: 768px) {
  .user-status {
    padding: 8px 0 4px 0;
    font-size: 20px;
  }
}
.user-name {
  line-height: 28px;
  font-weight: 700;
  margin: 6px 0;
  font-size: 15px;
}
@media (min-width: 768px) {
  .user-name {
    margin: 24px 0;
    font-size: 20px;
  }
}
.statusShopInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #313545;
}

.statusShopInfoName {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  h1 {
    text-align: center;
    font-size: 20px;
    font-weight: bold;
  }
}

@media (min-width: 768px) {
  .statusShopInfo {
    flex-direction: row;
    align-items: stretch;
    justify-content: start;
  }
  .statusShopInfoName {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    h1 {
      font-size: 34px;
      font-weight: bold;
    }
  }
}
.score-size {
  font-size: 15px;
  font-weight: 700;
}
@media (min-width: 768px) {
  .score-size {
    font-size: 34px;
  }
}
.store-detail {
  margin: 3px 0 8px 0;
}
@media (min-width: 768px) {
  .store-detail {
    margin: 20px 0 0px 0;
  }
}
@media (min-width: 1024px) {
  .store-detail {
    margin: 22px 0 0px 0;
  }
}

.gradient {
  background-image: linear-gradient(
    to bottom,
    #fa6d3e42 0%,
    rgb(255, 255, 255)
  );
  height: 20px;
  width: 100%;
  z-index: -1;
}
.bookingSuccessMain {
  width: 250px;
}

@media (min-width: 768px) {
  .bookingSuccessMain {
    width: 600px;
  }
}

@media (min-width: 1024px) {
  .bookingSuccessMain {
    width: 756px;
  }
}

.backgroundImg {
  background-image: url(../assets/image/balloon-1440@2x.webp);
  overflow: hidden;
  background-position: top top;
  background-size: contain;
  background-repeat: no-repeat;
  z-index: 99;
}
@media (max-width: 1024px) {
  .backgroundImg {
    background-image: url(../assets/image/balloon-834.webp);
  }
}

@media (max-width: 414px) {
  .backgroundImg {
    background-image: url(../assets/image/balloon-375.webp);
  }
}
.info-box-middle {
  padding: 0 30px;
  width: 300;
}

@media (min-width: 768px) {
  .info-box-middle {
    width: 560px;
  }
}
.keepMdHide {
  display: block;
}

@media (min-width: 768px) {
  .keepMdHide {
    display: none;
  }
}
.keepSmHide {
  display: none;
}

@media (min-width: 768px) {
  .keepSmHide {
    display: block;
  }
}
.storeLogo {
  width: 60px;
  height: 60px;
  margin-right: 0;
}

@media (min-width: 768px) {
  .storeLogo {
    width: 120px;
    height: 120px;
    margin-right: 1rem;
  }
}

/*! Flickity v2.2.2
https://flickity.metafizzy.co
---------------------------------------------- */
.flickity-enabled {
  position: relative;
}
.flickity-enabled:focus {
  outline: 0;
}
.flickity-viewport {
  overflow: hidden;
  position: relative;
  height: 110px !important;
  width: 100%;
  object-fit: cover;
  background-position: center center;
  // margin: 0 2.5rem;
}

@media (min-width: 768px) {
  .flickity-viewport {
    overflow: hidden;
    position: relative;
    height: 210px !important;
    width: 100%;
    object-fit: cover;
    background-position: center center;
    // margin: 0 2.5rem;
  }
}

.carousel-cell {
  object-fit: cover;
  background-position: center center;
  width: 110px;
  height: 110px;
  border-radius: 6px;
  margin-right: 8px;
}
@media (min-width: 768px) {
  .carousel-cell {
    object-fit: cover;
    background-position: center center;
    width: 210px;
    height: 210px;
    border-radius: 6px;
    margin-right: 10px;
  }
}

.flickity-slider {
  position: absolute;
  width: 100%;
  height: 100%;
  // transform: translateX(200px);
  // transform: translateX(5.46%) !important;
}
.flickity-enabled.is-draggable {
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.flickity-enabled.is-draggable .flickity-viewport {
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab;
}
.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}
.flickity-button {
  position: absolute;
  background: hsla(0, 0%, 100%, 0.75);
  border: none;
  color: #333;
}

.flickity-button:active {
  opacity: 0.6;
}
.flickity-button:disabled {
  opacity: 0.3;
  cursor: auto;
  pointer-events: none;
}
.flickity-button-icon {
  fill: currentColor;
}
.flickity-prev-next-button {
  top: 50%;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  transform: translateY(-50%);
}

///
.flickity-button {
  background: rgba(255, 255, 255, 0.692);
}

.flickity-prev-next-button {
  width: 20px;
  height: 20px;
  border-radius: 5px;
}

@media (min-width: 768px) {
  .flickity-prev-next-button {
    width: 30px;
    height: 30px;
  }
}

/* icon color */
.flickity-button-icon {
  fill: #313545;
}

.flickity.hidebtnSm button {
  display: none;
}

.flickity.hidebtn button {
  display: none;
}
</style>
