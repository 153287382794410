<template>
  <teleport to="body">
    <transition enter-active-class="transition ease-out duration-200 transform" enter-from-class="opacity-0" enter-to-class="opacity-100" leave-active-class="transition ease-in duration-200 transform" leave-from-class="opacity-100" leave-to-class="opacity-0">
      <div ref="modal-backdrop" class="fixed z-10 inset-0 overflow-y-auto bg-black bg-opacity-50" v-if="orderSuccess">
        <div class="flex items-start justify-center min-h-screen pt-24 text-center">
          <div class="bg-white rounded-lg text-left overflow-hidden shadow-xl w-10/12 md:w-1/2 lg:w-1/3" role="dialog" ref="modal" aria-modal="true" aria-labelledby="modal-headline" v-show="orderSuccess">
            <div class="rounded-lg bg-white flex flex-col items-center justify-center shadow-md" v-if="orderSuccess || !seatedSuccess">
              <div class="flex items-center  mt-10">
                <div class="cancelIcon">
                  <img src="@/assets/image/icon_booked.svg" alt="" />
                  <!-- <img class="wrong" src="@/assets/image/CheckedYes.png" alt="" /> -->
                  <!-- <img src="@/assets/image/cancel.png" alt="" /> -->
                </div>
                <p class=" md:text-2xl font-medium text-grayColor-darkest ">恭喜您訂位成功！</p>
              </div>
              <p class="mb-6 mt-3 md:my-8 text-center  text-grayColor successContent">訂位詳細資訊已寄送至您的電子信箱/手機簡訊！<br>
                至訂位日前24小時，系統會再次提醒通知。</p>
            </div>
          </div>
        </div>
      </div>
    </transition>

  </teleport>
</template>

<script>
import { ref, watch } from "vue";
import useClickOutside from "@/assets/filters/useClickOutside.js";

const props = {
  show: {
    type: Boolean,
    default: false,
  },
};
export default {
  name: "ModalDialog",
  props,
  setup(props) {
    const orderSuccess = ref(true);
    const modal = ref(null);
    const { onClickOutside } = useClickOutside();
    function closeModal() {
      orderSuccess.value = false;
    }
    onClickOutside(modal, () => {
      if (orderSuccess.value === true) {
        closeModal();
      }
    });

    watch(
      () => props.show,
      (show) => {
        orderSuccess.value = show;
      }
    );

    return {
      closeModal,
      orderSuccess,
      modal,
    };
  },
  methods: {
    getCookie(Name) {
      //cookie
      var search = Name + "=";
      var returnValue = "";

      if (document.cookie.length > 0) {
        var offset = document.cookie.indexOf(search);
        if (offset !== -1) {
          offset += search.length;
          var end = document.cookie.indexOf(";", offset);
          if (end == -1) {
            end = document.cookie.length;
          }
          returnValue = decodeURIComponent(
            document.cookie.substring(offset, end)
          );
        }
      }
      return returnValue;
    },
  },

  created() {
    if (this.getCookie("popped") == "") {
      //cookie 中没有 popped 则赋给他一个值（此时弹框显示）
      document.cookie = "popped = yes";
    } else {
      this.orderSuccess = false; //若cookie 中已经有 popped 值，则弹框再不会显示
    }
  },
};
</script>

<style lang="scss" scoped>
.cancelIcon {
  img {
    width: 24px;
    height: 24px;
    margin-right: 18px;
  }
}

@media (min-width: 768px) {
  img {
    width: 48px;
    height: 48px;
    margin-right: 18px;
  }
}

.successContent {
  font-size: 12px;
}

@media (min-width: 768px) {
  .successContent {
    font-size: 16px;
  }
}
</style>